import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Services = () => {
  return (
    <section className="min-h-[85vh] lg:min-h-[78vh] flex items-center" id="services">
      <div className="container mx-auto">
      {/* <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> */}
        <motion.div
          variants={fadeIn("up", 0.7)}
          initial="hidden"
          whileInView={"show"}
          className="text-center mb-12 pt-40" // Added padding for spacing
        >
          <h2 className="text-5xl font-bold">Resume</h2>
          <p className="text-xl text-gray-300">Check My Resume</p>
        </motion.div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Summary Column */}
          <motion.div
            variants={fadeIn("right", 0.7)}
            initial="hidden"
            whileInView={"show"}
            className="lg:w-1/3 space-y-6"
          >
            <div className="bg-transparent border-2 border-gray-700 p-6 rounded-lg">
              <h3 className="text-3xl font-semibold mb-4">Summary</h3>
              <div className="resume-item">
                <h4 className="text-xl font-bold mb-2">Alfin Varghese</h4>
                <ul className="list-disc pl-5 text-gray-300 space-y-2">
                  <li>Chettisseril (H), Puttady PO</li>
                  <li>+919947114553</li>
                  <li>alfinvarghese07@gmail.com</li>
                </ul>
              </div>
            </div>
          </motion.div>

          {/* Education Column */}
          <motion.div
            variants={fadeIn("left", 0.7)}
            initial="hidden"
            whileInView={"show"}
            className="lg:w-2/3 space-y-6"
          >
            <div className="bg-transparent border-2 border-gray-700 p-6 rounded-lg">
              <h3 className="text-3xl font-semibold mb-4">Education</h3>
              <div className="space-y-4">
                {/* PG Degree */}
                <div className="resume-item border-b border-gray-700 pb-4">
                  <h4 className="text-xl font-bold">
                    PG Degree - Master of Computer Applications
                  </h4>
                  <h5 className="text-md text-gray-400 mb-2">
                    Year: 2022-2024
                  </h5>
                  <p className="text-gray-300">
                    <em>University: Mahatma Gandhi University</em>
                  </p>
                  <p className="text-gray-300">
                    Marian College Kuttikkanam Autonomous Idukki, Kerala
                  </p>
                  <p className="text-gray-300">CGPA: 8.04</p>
                </div>

                {/* Bachelor's Degree */}
                <div className="resume-item border-b border-gray-700 pb-4">
                  <h4 className="text-xl font-bold">
                    Degree - Bachelor of Computer Applications
                  </h4>
                  <h5 className="text-md text-gray-400 mb-2">
                    Year: 2019-2022
                  </h5>
                  <p className="text-gray-300">
                    <em>University: Mahatma Gandhi University</em>
                  </p>
                  <p className="text-gray-300">
                    Christ College Puliyanmala Idukki, Kerala
                  </p>
                  <p className="text-gray-300">CGPA: 6.19</p>
                </div>

                {/* Plus Two */}
                <div className="resume-item border-b border-gray-700 pb-4">
                  <h4 className="text-xl font-bold">
                    Plus Two - Computer Science
                  </h4>
                  <h5 className="text-md text-gray-400 mb-2">
                    Year: 2017-2019
                  </h5>
                  <p className="text-gray-300">
                    <em>St George HSS Kattappana</em>
                  </p>
                  <p className="text-gray-300">
                    Board: Higher Secondary Board of Examination
                  </p>
                  <p className="text-gray-300">Percentage: 70%</p>
                </div>

                {/* SSLC */}
                <div className="resume-item">
                  <h4 className="text-xl font-bold">SSLC</h4>
                  <h5 className="text-md text-gray-400 mb-2">Year: 2017</h5>
                  <p className="text-gray-300">
                    <em>St Antony's HS Vandenmedu</em>
                  </p>
                  <p className="text-gray-300">Board: State Board</p>
                  <p className="text-gray-300">Percentage: 95%</p>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Experience Column */}
          <motion.div
            variants={fadeIn("right", 0.7)}
            initial="hidden"
            whileInView={"show"}
            className="lg:w-1/3 space-y-6"
          >
            <div className="bg-transparent border-2 border-gray-700 p-6 rounded-lg">
              <h3 className="text-3xl font-semibold mb-4">Experience</h3>
              <div className="resume-item">
                <h4 className="text-2xl font-bold mb-3">Associate Developer</h4>
                <h4 className="text-xl font-bold mb-2">
                  Woxro Technology Solutions Infopark Koratty, Thrissur
                </h4>
                <ul className="list-disc pl-5 text-gray-300 space-y-2">
                  <li>React Native, React.js, Expo, JavaScript</li>
                  <li>
                    Created cross-platform mobile apps with React Native,
                    utilizing object-oriented principles and ensuring high
                    cohesion and maintainability.
                  </li>
                </ul>
              </div>
              <br />
              <div className="resume-item">
                <h4 className="text-2xl font-bold mb-3">Web Developer</h4>
                <h4 className="text-xl font-bold mb-2">
                  CreaveLabs IT Solutions LLP
                </h4>
                <ul className="list-disc pl-5 text-gray-300 space-y-2">
                  <li>Python, Django, HTML, CSS, JavaScript</li>
                  <li>
                    Analyzed client requirements and contributed to project
                    documentation, enhancing alignment with project objectives
                    and client expectations.
                  </li>
                  <li>
                    Developed an Employee Camp Management System using Python
                    Django and MySQL, integrating user features and ensuring
                    unit-level validation.
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
