import React from 'react';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';

const App = () => {
      return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      <Header />
      <Banner />
      <Nav />
      <About />
      <Services />
      <Contact />
      <Work />
      
      <div className='h-[400px]'>
        </div> 
        <footer className="bg-gray-900 text-center py-6 mt-12">
        <p className="text-gray-400">
          Designed by <span className="text-blue-500 font-semibold">Alfin Varghese</span> | © {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  );
};

export default App;
