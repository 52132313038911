import React from "react";
import Image from "../assets/about.jpg"; // Corrected import
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const About = () => {
  return (
    <section className="min-h-[100vh] lg:min-h-[100vh] flex items-center" id="about">
      <div className="container mx-auto flex flex-col lg:flex-row items-center gap-8">
        {/* Image Section */}
        <motion.div
          variants={fadeIn("down", 0.7)}
          initial="hidden"
          whileInView={"show"}
          className="lg:flex-1 flex justify-center"
        >
          <img
            src={Image}
            alt="About me"
            className="rounded-full w-80 h-95 object-cover" // Tailwind CSS classes for rounded corners
          />
        </motion.div>

        {/* Text Section */}
        <div className="lg:flex-1 text-center lg:text-left space-y-4">
          <h2 className="text-5xl font-bold">About me</h2>
          <p className="text-lg text-white-700">
            I'm Alfin Varghese, a recent MCA graduate specializing in Big Data
            Analytics and software development. With a solid foundation in
            programming languages like Python, Java, and C++, I have experience
            in web development through projects and internships. I’m skilled in
            technologies like React.js, Django, and cloud platforms like AWS. I
            am passionate about leveraging data to drive insights and am eager
            to start my career in software engineering or data analytics, where
            I can contribute and grow.
          </p>
        </div>
      </div>
      <br/>
    </section>
  );
};

export default About;
