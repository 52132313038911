import React from "react";
import { motion } from "framer-motion";
import Image from "../assets/park.png";
import Image1 from "../assets/Shopp.jpg";
import Image2 from "../assets/arc.jpg";
import Image3 from "../assets/travel app.jpg";
import { fadeIn } from "../variants";

const Work = () => {
  const projects = [
    {
      title: "Parking Management System",
      description:
        "A web application to help users find and reserve parking spots in the city. Features include user login, location search, and real-time parking availability.",
      image: Image, // Use the imported image here
      //gitLink: "https://github.com/alfinvarghese/parking-management-system", // Replace with your GitHub repo link
    },
    {
      title: "Architecture Plans Marketplace",
      description:
        "A platform connecting architects and customers. Architects can upload their designs, and customers can browse or request custom plans.",
      image: Image2, // Use the imported image here
      //gitLink: "https://github.com/alfinvarghese/architecture-plans-marketplace", // Replace with your GitHub repo link
    },
    {
      title: "Shopping App",
      description:
        "A user-friendly shopping app that allows customers to browse products, add them to a cart, and complete purchases seamlessly.",
      image: Image1, // Use the imported image here
      //gitLink: "https://github.com/alfinvarghese/shopping-app", // Replace with your GitHub repo link
    },
    {
      title: "Travel App",
      description:
        "A mobile app for planning trips, including searching for destinations, booking accommodations, and managing itineraries.",
      image: Image3, // Use the imported image here
      // gitLink: "https://github.com/alfinvarghese/travel-app", // Replace with your GitHub repo link
    },
  ];

  return (
    <section className="min-h-[85vh] lg:min-h-[78vh] flex flex-col" id="work">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-5xl font-bold">My Projects</h2>
          <p className="text-lg text-gray-300">Explore some of my recent work</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              className="bg-gray-800 rounded-lg overflow-hidden shadow-lg"
            >
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-56 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-3">{project.title}</h3>
                <p className="text-gray-300 mb-4">{project.description}</p>
                <a
                  href={project.gitLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Project
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Footer Section */}
      
    </section>
  );
};

export default Work;
